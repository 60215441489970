export const address = {
  name: 'Belmondo Books',
  address: 'Joan Muyskenweg 22',
  zipcode: '1096 CJ',
  city: 'Amsterdam',
};

export const contact = {
  phone: '020-330 55 77',
  email: 'hallo@belmondofoto.nl',
};

export const details = {
  website: 'Belmondofoto.nl',
  kvk: '34330033',
  btw: '8205.22.387.B.01',
};
