import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  backgroundColored,
  brand,
  ctaHover,
  fontDark,
} from '@constants/colors';
import { address, details } from '@constants/company-info';
import { navigation, policy, socials } from '@constants/footer-data';
import { laptop, smallTablet, tablet } from '@constants/media-queries';
import { contentWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Title from '@elements/Title';

const Component = styled.footer`
  color: ${backgroundColored};
  background-color: ${fontDark};
  padding: ${space * 8}px ${space * 4}px ${space * 4}px;

  li {
    list-style: none;
  }

  a {
    line-height: 32px;
    &:hover {
      color: white;
    }
  }

  button {
    flex-shrink: 0;
  }

  small {
    display: block;
    text-align: center;
  }
`;

const NewsletterContainer = styled.div`
  padding: ${space * 3}px;
  background-color: ${brand};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${space * 3}px;
  justify-content: center;
  align-items: center;

  ${tablet} {
    flex-direction: row;
  }
`;

const StyledTitle = styled(Title)`
  color: white;
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  background-color: ${ctaHover};

  :hover {
    background-color: white;
    color: ${brand};
    border-color: white;
  }
`;

const Container = styled.div`
  max-width: ${contentWidth};
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  text-align: center;

  a {
    text-decoration: none;
  }

  ${smallTablet} {
    text-align: left;
  }
`;

const Navigation = styled.nav`
  flex-grow: 1;
  flex-basis: 100%;

  ${laptop} {
    flex-basis: auto;
  }

  & > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Form = styled.form`
  margin: 0 auto;
  ${smallTablet} {
    margin: 0;
  }

  & > div {
    display: flex;
  }
`;

const SectionName = styled.p`
  color: ${backgroundColored};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Section = styled.li`
  margin-bottom: ${space * 5}px;
  flex-basis: 100%;

  ${smallTablet} {
    flex-basis: 33%;
  }
`;

const Icons = styled.ul`
  display: flex;
  justify-content: center;

  ${smallTablet} {
    justify-content: flex-start;
  }
  & > li {
    margin-right: ${space}px;
  }

  & a {
    padding: 10px 14px;
    font-size: 24px;
    ${laptop} {
      padding: 3px;
    }
  }
`;

const Legal = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > li:not(:last-of-type) {
    margin-right: ${space * 3}px;
  }

  & a {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const handlePopup = () => {
    //trigger poptin email popup
    if (
      typeof window !== 'undefined' &&
      typeof window.poptin_display === 'function'
    ) {
      window.poptin_display('44fa6e2d8e677');
      console.log('geklikt');
    } else {
      console.warn('Poptin popup is not available.');
    }
  };

  const renderNavigation = useMemo(
    () =>
      navigation.map((section, i) => (
        <Section key={i}>
          <SectionName>{section.label}</SectionName>
          <ul>
            {section.links.map((link, index) => {
              return (
                <li key={index}>
                  {link.external ? (
                    <a href={link.href} target="_blank" rel="noreferrer">
                      {link.label}
                    </a>
                  ) : (
                    <Link href={link.href}>{link.label}</Link>
                  )}
                </li>
              );
            })}
          </ul>
        </Section>
      )),
    [],
  );

  const renderSocials = useMemo(
    () =>
      socials.map((section, i) => (
        <Section key={i}>
          <SectionName>{section.label}</SectionName>
          <Icons>
            {section.links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  aria-label={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={link.icon} />
                </a>
              </li>
            ))}
          </Icons>
        </Section>
      )),
    [],
  );

  const renderPolicy = useMemo(
    () =>
      policy.map((link, i) => (
        <li key={i}>
          {link.external ? (
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          ) : (
            <Link href={link.href} passHref>
              <a>{link.label}</a>
            </Link>
          )}
        </li>
      )),
    [],
  );

  return (
    <>
      <NewsletterContainer>
        <StyledTitle as="h5" size="sm">
          Schrijf je in voor onze nieuwsbrief en ontvang €5 korting!
        </StyledTitle>
        <StyledButton onClick={handlePopup}>Inschrijven</StyledButton>
      </NewsletterContainer>
      <Component>
        <Container>
          <Navigation>
            <ul>
              {renderNavigation}
              {renderSocials}
            </ul>
          </Navigation>
        </Container>
        <small>
          {details.website}, {address.address}, {address.zipcode} {address.city}
          <br />© {new Date().getFullYear()} {details.website}
          <Legal>{renderPolicy}</Legal>
        </small>
      </Component>
    </>
  );
};

export default Footer;
