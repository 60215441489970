import {
  faFacebookF,
  faInstagram,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';

import {
  allProducts,
  belmondoWebsite,
  durability,
  exampleBooks,
  explanation,
  facebook,
  faq,
  instargram,
  ourStory,
  pinterest,
  prices,
  privacy,
  terms,
} from '@constants/routes';

export const navigation = [
  {
    label: 'Belmondofoto',
    links: [
      {
        label: 'Thema’s',
        href: allProducts,
      },
      {
        label: 'Voorbeelden',
        href: exampleBooks,
      },
      {
        label: 'Prijzen',
        href: prices,
      },
      {
        label: 'Bekijk uitleg',
        href: explanation,
      },
      {
        label: 'Klantenservice',
        href: faq,
        external: true,
      },
    ],
  },
  {
    label: 'Over Belmondo',
    links: [
      {
        label: 'Ons verhaal',
        href: ourStory,
      },
      // {
      //   label: 'Werken bij Belmondo',
      //   href: '#1',
      // },
      {
        label: 'Duurzaamheid',
        href: durability,
      },
      {
        label: 'Voor organisaties',
        href: belmondoWebsite,
        external: true,
      },
    ],
  },
];

export const socials = [
  {
    label: 'Volg ons',
    links: [
      {
        label: 'Instagram',
        href: instargram,
        icon: faInstagram,
      },
      {
        label: 'Pinterest',
        href: pinterest,
        icon: faPinterestP,
      },
      {
        label: 'Facebook',
        href: facebook,
        icon: faFacebookF,
      },
    ],
  },
];

export const policy = [
  {
    label: 'Algemene voorwaarden',
    href: terms,
  },
  {
    label: 'Privacy',
    href: privacy,
  },
  {
    label: 'Klantenservice',
    href: faq,
    external: true,
  },
];
